import styled from "styled-components";

import { Colors, Sizes } from "../../Constants/Constants";

const StyledButton = styled.button`
  background-color: ${(props) => (props.primary ? Colors.Black : Colors.White)};
  color: ${(props) => (props.primary ? Colors.White : Colors.Black)};
  border: 1px solid ${Colors.Black};
  font-weight: 600;
  text-transform: uppercase;
  margin: ${Sizes.XSmall};
  font-size: ${Sizes.XXSmall};
  width: ${Sizes.XXLarge};
  height: ${Sizes.Large};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    color: ${Colors.White};
    background-color: ${Colors.Black};
  }
`;

const Button = (props) => {
  const { onClick, title, primary, disabled } = props;
  return (
    <StyledButton disabled={disabled} primary={primary} onClick={onClick}>
      {title}
    </StyledButton>
  );
};

export default Button;
