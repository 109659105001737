import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import AskForPrice from "../../components/AskForPrice/AskForPrice";
import PageHeader from "../../components/shared/PageHeader/PageHeader";
import Footer from "../../components/shared/Footer/Footer";
import { Wrapper } from "../../components/shared/BaseStyledComponents";
import CarpetDetailsTable from "../../components/CarpetDetailsTable/CarpetDetailsTable";
import "react-image-gallery/styles/css/image-gallery.css";
import SpinnerLinear from "../../components/shared/SpinnerLinear/SpinnerLinear";
import styled from "styled-components";
import { Sizes } from "../../components/Constants/Constants";
import {  useSelector } from "react-redux";
import allCarpetsEnglish from "../../database/allCarpetsEnglish.json";
import allCarpets from "../../database/allCarpets.json";

const CarpetTitle = styled.span`
  font-size: ${Sizes.Medium};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: ${Sizes.Small};
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.Small};
  }
`;

const Carpet = () => {
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [carpetType, setCarpetType] = useState("");
  const [carpet, setCarpet] = useState();
  const subDomain = location.pathname.split("/")[1];
  const carpetID = location.pathname.split("/")[2];
  const [images, setImages] = useState([]);
  const language = useSelector(
    (state) => state.languageStatus
  );

  useEffect(() => {
    setCarpetType(subDomain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  useEffect(() => {
    if (carpetType) {
      const selectedCarpets = language === "en" ? allCarpetsEnglish : allCarpets;
      const item = selectedCarpets[carpetType].find(
        (item) => item.number === carpetID
      );
      setCarpet(item);
    }
  }, [carpetType, carpetID, language]);

  useEffect(() => {
    const imagesArray = [];
    if (carpet) {
      carpet.imgs.forEach((item) => {
        const img = {
          original: "",
          thumbnail: "",
        };
        img.original = item;
        img.thumbnail = item;
        imagesArray.push(img);
      });
      setImages(imagesArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carpet]);

  const askForPrice = () => {
    setModalShow(true);
  };

  return (
    <Wrapper>
      <PageHeader />

      {images.length > 0 && (
        <>
          <CarpetTitle>{carpet.title}</CarpetTitle>
          <ImageGallery
            lazyLoad={true}
            items={images}
            showPlayButton={false}
            loading={<SpinnerLinear />}
          />
          <CarpetDetailsTable carpet={carpet} askForPrice={askForPrice} />
        </>
      )}

      {modalShow && (
        <AskForPrice
          carpet={carpet}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Footer />
    </Wrapper>
  );
};

export default Carpet;
