import { ApiStatus } from "../components/Constants/Constants";

const initialState = {
  apiStatus: {
    sendEmailAskForPrice: ApiStatus.Idle,
  },
  language:'sv'
};

export default initialState;
