import Modal from "react-modal";
import { ApiStatus, Sizes } from "../Constants/Constants";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import InputField from "../shared/InputField/InputField";
import { useState } from "react";
import Button from "../shared/Button/Button";

import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import {
  makeIdleSendAskForPriceEmail,
  sendAskForPriceEmail,
} from "../../store/actions/sendEmail";
import SpinnerLinear from "../shared/SpinnerLinear/SpinnerLinear";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 1rem;
  width: ${Sizes.XXXXLarge};
  @media (max-width: 768px) and (min-width: 300px) {
    justify-content: center;
    width: ${Sizes.XXXLarge};
    margin: ${Sizes.XSmall};
  }
`;

const ThankyouTitle = styled.span`
  font-size: ${Sizes.Medium};
  font-weight: bold;
  letter-spacing: 2px;
  margin: ${Sizes.Small} 0;
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.XSmall};
  }
`;

const ThankyouMessage = styled.span`
  font-size: ${Sizes.Small};
  letter-spacing: 2px;
  margin: ${Sizes.Small} 0;
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.XSmall};
  }
`;

const ModalTitle = styled.span`
  font-size: ${Sizes.Medium};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: ${Sizes.Small} 0;
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.Small};
  }
`;

const Textarea = styled.textarea`
  resize: none;
  margin: ${Sizes.Small} 0;
  width: 100%;
  height: ${Sizes.XXXLarge};
  @media (max-width: 768px) and (min-width: 300px) {
    width: ${Sizes.XXXLarge};
    height: ${Sizes.XLarge};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AskForPrice = (props) => {
  const { t } = useTranslation("translations");
  const dispatch = useDispatch();
  const apiStatus = useSelector(
    (state) => state.apiStatus.sendEmailAskForPrice
  );
  const [state, setState] = useState({
    name: "",
    tel: "",
    email: "",
    message: "",
  });
  const onChangeHandler = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const sendEmail = () => {
    //sendgrid email sending with account:peyman_davoody@yahoo.com
    //dont forget to check the api, sender, fix the dns in sendgrid
    const data = {
      ...state,
      title: props.carpet.title,
      itemNumber: props.carpet.number,
    };
    dispatch(sendAskForPriceEmail(data));
  };

  const onCancelClick = () => {
    props.setModalShow(false);
    dispatch(makeIdleSendAskForPriceEmail());
  };

  return (
    <Modal
      isOpen={props.modalShow}
      ariaHideApp={false}
      className="mymodal"
      overlayClassName="myoverlay"
      onRequestClose={onCancelClick}
      closeTimeoutMS={500}
    >
      <ModalContainer>
        {apiStatus === ApiStatus.Started && <SpinnerLinear />}
        {apiStatus === ApiStatus.Completed && (
          <>
            <ThankyouTitle>{t("CarpetPage.thankyou")}</ThankyouTitle>
            <ThankyouMessage>{t("CarpetPage.thankyouText")}</ThankyouMessage>
          </>
        )}
        {apiStatus === ApiStatus.Idle && (
          <>
            <ModalTitle>{props.carpet.title}</ModalTitle>
            <InputField
              name="name"
              placeholder={t("CarpetPage.yourName")}
              onChange={onChangeHandler}
            />
            <InputField
              name="email"
              placeholder={t("CarpetPage.email")}
              onChange={onChangeHandler}
            />
            <InputField
              name="tel"
              placeholder={t("CarpetPage.tel")}
              onChange={onChangeHandler}
            />
            <Textarea
              name="message"
              placeholder={t("CarpetPage.message")}
              onChange={onChangeHandler}
            />
          </>
        )}
      </ModalContainer>
      <ButtonsContainer>
        <Button
          title={t("CarpetPage.cancel")}
          onClick={onCancelClick}
          disabled={apiStatus === ApiStatus.Started}
        />
        {apiStatus === ApiStatus.Idle && (
          <Button primary title={t("CarpetPage.send")} onClick={sendEmail} />
        )}
      </ButtonsContainer>
    </Modal>
  );
};

export default AskForPrice;
