import styled from "styled-components";
import { Colors, Sizes } from "../../Constants/Constants";

const DropDownContainer = styled.div`
  width: 100%;
  margin: 0.3em 0;
  color: ${Colors.Gray};
`;

const Label = styled.label`
  color: ${Colors.Gray};
  text-transform: uppercase;
  font-size: ${Sizes.XSmall};
  font-weight: 600;
  margin-bottom: 0.3em;
  display: inline-block;
`;

const Select = styled.select`
  background-color: ${Colors.White}
  color: ${Colors.Black}
  opacity: 1;
  line-height: 1.3;
  padding: 0.8rem 0.7rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${Sizes.XSmall};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M92.2276 157.154L3.21959 68.1456C-1.0732 63.8529 -1.0732 56.8932 3.21959 52.6008L13.6009 42.2195C17.8864 37.934 24.8319 37.9258 29.1274 42.2012L100 112.742L170.873 42.2012C175.168 37.9258 182.114 37.934 186.399 42.2195L196.78 52.6008C201.073 56.8936 201.073 63.8533 196.78 68.1456L107.773 157.154C103.48 161.446 96.5204 161.446 92.2276 157.154Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.8em auto, 100%;
  &:not(:disabled) {
    cursor: pointer;
  }
  margin-top: 0.2em;
  border: 0.1em solid ${Colors.Gray};

  &:not(:disabled) {
    &:hover {
      border-color: ${Colors.Black};
    }
    &:focus {
      border-color: ${Colors.DarkBlue};
    }
    &.has-error:focus {
      border-color: ${Colors.Warning};
    }
  }

  /* Hide arrow icon in IE browsers */
  ::-ms-expand {
    display: none;
  }


  outline: none;
`;

const Option = styled.option`
  // max-width: 100%;
`;

const Dropdown = (props) => {
  const dropDownValues = props.dropDownValues || [];

  return (
    <DropDownContainer>
      {props.label && (
        <Label labelColor={props.labelColor}>{props.label}</Label>
      )}
      <Select
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        {dropDownValues.map((option, index) => (
          <Option
            key={`${option.value}-${option.label}-${index}`}
            value={option.value}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </DropDownContainer>
  );
};

export default Dropdown;
