import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Sizes } from "../../Constants/Constants";
import Dropdown from "../Dropdown/Dropdown";
import { useDispatch } from "react-redux";
import {updateLanguage} from '../../../store/actions/language'

const TranslationContainer = styled.div`
  width: ${Sizes.XLarge};
`;

const Container = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const Translation = () => {
  const [t, i18n] = useTranslation("translations");
  const [lang, setLang] = useState("sv");
  const dispatch=useDispatch();

  const onChangeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    setLang(e.target.value);
    dispatch(updateLanguage(e.target.value))
  };

  return (
    <Container>
      <TranslationContainer>
        <Dropdown
          dropDownValues={[
            {
              label: "EN",
              value: "en",
            },
            {
              label: "SV",
              value: "sv",
            },
          ]}
          name="translation"
          defaultValue={lang}
          onChange={onChangeLanguageHandler}
        ></Dropdown>
      </TranslationContainer>
    </Container>
  );
};
export default Translation;
