import { Colors, Sizes } from "../../components/Constants/Constants";
import Button from "../../components/shared/Button/Button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CarpetDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 1rem;
  border: 1px solid ${Colors.Gray};
  width: 80%;
  @media (max-width: 768px) and (min-width: 300px) {
    justify-content: center;
    width: 90%;
  }
`;
const CarpetDetail = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
  border-bottom: 1px solid ${Colors.Gray};
  width: 100%;
  @media (max-width: 768px) and (min-width: 300px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const CarpetTitle = styled.span`
  font-size: ${Sizes.Medium};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: ${Sizes.Small};
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.Small};
  }
`;

const CarpetDetailText = styled.span`
  font-size: ${Sizes.XSmall};
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin: ${Sizes.Small};
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.XSmall};
    font-size: ${Sizes.XSmall};
  }
`;

const CarpetDetailValue = styled.span`
  font-size: ${Sizes.XSmall};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: ${Sizes.Small};
  color: ${Colors.DarkBlue};
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.XSmall};
  }
`;

const CarpetDetailsTable = (props) => {
  const { carpet, askForPrice } = props;
  const { t } = useTranslation("translations");

  return (
    <CarpetDetailsContainer>
      <CarpetTitle>{carpet.title}</CarpetTitle>
      {carpet.name&&
      <CarpetDetail>
        <CarpetDetailText>{t("CarpetPage.name")}</CarpetDetailText>
        <CarpetDetailValue>{carpet.name}</CarpetDetailValue>
      </CarpetDetail>
      }
      <CarpetDetail>
        <CarpetDetailText>{t("CarpetPage.number")}</CarpetDetailText>
        <CarpetDetailValue>{carpet.number}</CarpetDetailValue>
      </CarpetDetail>
      
      <CarpetDetail>
        <CarpetDetailText>{t("CarpetPage.monogramSigned")}</CarpetDetailText>
        <CarpetDetailValue>{carpet.signiture}</CarpetDetailValue>
      </CarpetDetail>
      <CarpetDetail>
        <CarpetDetailText>{t("CarpetPage.nationality")}</CarpetDetailText>
        <CarpetDetailValue>{carpet.nationality}</CarpetDetailValue>
      </CarpetDetail>
      <CarpetDetail>
        <CarpetDetailText>{t("CarpetPage.size")}</CarpetDetailText>
        <CarpetDetailValue>{carpet.size}</CarpetDetailValue>
      </CarpetDetail>
      {carpet.design && (
        <CarpetDetail>
          <CarpetDetailText>{t("CarpetPage.design")}</CarpetDetailText>
          <CarpetDetailValue>{carpet.design}</CarpetDetailValue>
        </CarpetDetail>
      )}
      {carpet.age && (
        <CarpetDetail>
          <CarpetDetailText>{t("CarpetPage.age")}</CarpetDetailText>
          <CarpetDetailValue>{carpet.age}</CarpetDetailValue>
        </CarpetDetail>
      )}
      <CarpetDetail>
        <CarpetDetailText>{t("CarpetPage.price")}</CarpetDetailText>
        {carpet.price && (
          <CarpetDetailValue>{carpet.price} SEK</CarpetDetailValue>
        )}
      </CarpetDetail>

      <Button
        primary
        title={t("CarpetPage.askAboutPrice")}
        onClick={askForPrice}
      />
    </CarpetDetailsContainer>
  );
};

export default CarpetDetailsTable;
